/**
 * Please add any constants that are used in multiple places within Endil to this file.
 * A constant's identifier should be descriptive and unique to make them easy to find via a
 * text search of the codebase. If you add similar identifiers please choose a reasonable prefix
 * followed by an underscore (example `DATADOG_`). If possible avoid nesting one identifier
 * within another to avoid false positives from text searches. For example `BERRY_PIE` and
 * `STRAWBERRY_PIE` are problematic where `PIE_BERRY` and `PIE_STRAWBERRY` are not.
 *
 * Please add a JSDoc style description to any configuration entries you add. This serves as
 * documentation within this file and provides helpful tooltips in VSCode.
 */

enum Environments {
  DEVELOPMENT = "development",
  STAGING = "staging",
  PRODUCTION = "production",
}

export enum MCPermissions {
  AUDIENCE_PROFILE_PERM = "application:audience-profile",
  BRANDS_PERM = "application:brands",
  DELIVERABLES_HUB_PERM = "application:deliverables-hub",
  ECONOMIC_PERM = "application:economic",
  POLITICAL_PERM = "application:political",
  PRO_PERM = "application:pro",

  INTERNAL_CMS_USER = "application:cms",

  PRO_FULL_ACCESS_PERM = "pro:full-access",
  PRO_CONSUMER_PLUS = "pro:consumer-plus",
  PRO_ECONOMIC_PLUS = "pro:economic-plus",
  PRO_POLITICAL_PLUS = "pro:political-plus",
}

export enum EventTreeTarget {
  CONTENT_ITEM_HEADLINE_LINK = "content-item-headline-link",
  CONTENT_ITEM_IMAGE_LINK = "content-item-image-link",
  CONTENT_ITEM_KICKER_LINK = "content-item-kicker-link",
  HOMEPAGE_SEARCH_OR_CTA_AUTHENTICATED = "homepage-search-or-cta-authenticated",
  HOMEPAGE_SEARCH_OR_CTA_UNAUTHENTICATED = "homepage-search-or-cta-unauthenticated",
  HOMEPAGE_SUBSCRIPTION_BLOCK = "homepage-subscription-block",
  HOMEPAGE_TABBED_GRID_BUTTON = "homepage-tabbed-grid-button",
}

export enum CommonIntervals {
  ONE_SECOND_IN_MS = 1000,
  TEN_SECONDS_IN_MS = 1000 * 10,
  TEN_MINUTES_IN_MS = 1000 * 60 * 10,
  ONE_HOUR_IN_MS = 1000 * 60 * 60,
  ONE_DAY_IN_MS = 1000 * 60 * 60 * 24,
  FIVE_MINUTES_IN_MS = 1000 * 5 * 60,
}

export enum IdentityProviderNames {
  GOOGLE = "Google",
  LINKEDIN = "LinkedIn",
  MICROSOFT = "Microsoft",
}

export enum LandingPageEvent {
  CONTACT_US_FORM_SUBMIT = "Contact Us Form Submission",
  REQUEST_DEMO_FORM_SUBMIT = "Demo Request Form Submission",
  ONBOARDING_FORM_SUBMIT = "Onboarding Form Submission",
}

export const constants = {
  /**
   * Header used to ensure traffic originates from the Cloudflare proxy
   */
  CLOUDFLARE_PROXY_VALIDATION_HEADER: "x-mc-proxyvalidation",

  /**
   * Common intervals
   */
  CommonIntervals,

  /**
   * Cookie name for HTTP only cookie used for CSRF protection
   */
  CSRF_COOKIE_NAME: "pro_csrf_token",

  /**
   * Header used to pass the CSRF token from the client for validation on the server
   */
  CSRF_HEADER_NAME: "X-Pro-CSRF-Token",

  /**
   * The service name used by DataDog for client side logging and Real User Monitoring (RUM).
   * IMPORTANT: Please do not alter this value!
   */
  DATADOG_SERVICE_NAME: "endil",

  /**
   * Used to identify development, staging and production environments
   */
  Environments,

  /**
   * Used to target events that bubble up via the data-event-tree-target attribute
   */
  EventTreeTarget,

  /**
   * MC Platform Permissions
   */
  MCPermissions,

  /**
   * Local storage key used to store global, cross window, persistent state
   */
  PERSISTENT_STATE_STORAGE_KEY: "persistent-state",

  /**
   * Local storage key used to set the IDP name selected at registration (if any)
   */
  REGISTRATION_IDP_NAME_STORAGE_KEY: "endil-reg-idp-name",

  /**
   * Local storage key used to store the user's redirect location on successful login.
   */
  REGISTRATION_REDIRECT_STORAGE_KEY: "endil-reg-redirect-path",

  /**
   * The salesforce campaign id for the checkout onboarding form
   */
  SALESFORCE_ONBOARDING_CAMPAIGN_ID: "701Rl00000FJ2n4IAD",

  /**
   * Local storage key for showing sticky newsletter banner
   */
  STICKY_NEWSLETTER_STORAGE_KEY: "endil-sticky-newsletter",

  /**
   * The property name used to identify a turnstile result on the API.
   * This is handled by a wrapApi decorator function found in `~/lib/server/wrapApi/validateTurnstileDecorator`
   *
   * This constant can be used to consistently apply the same property name across the app
   */
  TURNSTILE_RESULT_PROPERTY: "turnstileResult",
} as const;
