import {
  MCFieldMenuItem,
  MCIntelMenuItem,
  MCProMenuItem,
  ProductMenu,
  MenuApp,
  MenuEventName,
  MenuEventProperty,
} from "@mci/product-menu";
import { cx } from "cva";
import { SessionContextValue } from "next-auth/react";
import React, { PropsWithChildren } from "react";
import { useAnalytics } from "~/components/Analytics";
import { LayoutSearchInput } from "~/components/Layout/LayoutSearchInput/LayoutSearchInput";
import { LoginButton } from "~/components/LoginButton";
import { SearchMenu } from "~/components/SearchMenu";
import { UserMenuButton } from "~/components/UserMenuButton";
import { commonConfig } from "~/config/common-config";
import { ButtonLink } from "~/ui-library/components/ButtonLink/ButtonLink";
import { buttonStyles } from "~/ui-library/shared-styles/shared-button-styles";
import { LayoutUserActionsSkeleton } from "../LayoutUserActionsSkeleton/LayoutUserActionsSkeleton";

export type LayoutDesktopUserToolbarProps = {
  hasFieldAccess?: boolean;
  hasMciAccess?: boolean;
  status: SessionContextValue["status"] | "subscribed";
  userInitials: string | null;
};

const Container: React.FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => (
  <div
    className={cx(
      "relative flex h-[64px] items-center gap-m xl:gap-s",
      className
    )}
  >
    {children}
  </div>
);

const SignInToolbarButton: React.FC<{ disabled?: boolean }> = ({
  disabled = false,
}) => (
  <LoginButton
    behavior={{ type: "window" }}
    className={buttonStyles({
      className: "flex items-center whitespace-nowrap",
      disabled,
      variant: "subtle",
    })}
  >
    Sign in
  </LoginButton>
);

const SubscribeButton: React.FC<{ disabled?: boolean }> = ({
  disabled = false,
}) => (
  <ButtonLink disabled={disabled} href="/subscribe" variant="primary">
    Subscribe
  </ButtonLink>
);

const SearchMenuExpandsOnXL = () => (
  <>
    <div className="xl:hidden">
      <SearchMenu />
    </div>
    <div className="mr-m hidden min-w-[280px] xl:block">
      <LayoutSearchInput fullWidth />
    </div>
  </>
);

const FilledProductMenu: React.FC<
  Required<
    Pick<LayoutDesktopUserToolbarProps, "hasFieldAccess" | "hasMciAccess">
  >
> = ({ hasFieldAccess, hasMciAccess }) => {
  const { analyticsEnhancedTrack } = useAnalytics();

  return (
    <ProductMenu>
      <MCProMenuItem
        data-testid="mc-pro-link"
        href={commonConfig.NEXT_PUBLIC_ENDIL_URL}
      />
      <MCIntelMenuItem
        data-testid="mc-intel-link"
        disabled={!hasMciAccess}
        href={commonConfig.NEXT_PUBLIC_MCI_URL}
        onClick={() => {
          if (hasMciAccess) {
            analyticsEnhancedTrack(MenuEventName.ClickedItem, {
              [MenuEventProperty.Destination]: MenuApp.Intel,
              [MenuEventProperty.Source]: MenuApp.Pro,
            });
          }
        }}
        target="_blank"
      />
      <MCFieldMenuItem
        data-testid="mc-field-link"
        disabled={!hasFieldAccess}
        href={commonConfig.NEXT_PUBLIC_MC_FIELD_URL}
        onClick={() => {
          if (hasFieldAccess) {
            analyticsEnhancedTrack(MenuEventName.ClickedItem, {
              [MenuEventProperty.Destination]: MenuApp.Field,
              [MenuEventProperty.Source]: MenuApp.Pro,
            });
          }
        }}
        target="_blank"
      />
    </ProductMenu>
  );
};

export const LayoutDesktopUserToolbar: React.FC<
  LayoutDesktopUserToolbarProps
> = ({ hasFieldAccess, hasMciAccess, status, userInitials }) => {
  const showProductMenu = hasFieldAccess || hasMciAccess;

  if (status === "authenticated") {
    return (
      <Container className={showProductMenu ? "" : "mr-m"}>
        <SearchMenuExpandsOnXL />
        <UserMenuButton initials={userInitials!} />
        <SubscribeButton />
        {showProductMenu ? (
          <FilledProductMenu
            hasFieldAccess={Boolean(hasFieldAccess)}
            hasMciAccess={Boolean(hasMciAccess)}
          />
        ) : null}
      </Container>
    );
  }

  if (status === "subscribed") {
    return (
      <Container className={showProductMenu ? "" : "mr-m"}>
        <SearchMenuExpandsOnXL />
        <UserMenuButton initials={userInitials!} />
        {showProductMenu ? (
          <FilledProductMenu
            hasFieldAccess={Boolean(hasFieldAccess)}
            hasMciAccess={Boolean(hasMciAccess)}
          />
        ) : null}
      </Container>
    );
  }

  if (status === "unauthenticated") {
    return (
      <Container className={showProductMenu ? "" : "mr-m"}>
        <SearchMenuExpandsOnXL />
        <SignInToolbarButton />
        <SubscribeButton />
      </Container>
    );
  }

  /** In the event we are loading or are in an unknown state, render placeholder  */
  return (
    <Container className="mr-m">
      <LayoutUserActionsSkeleton />
    </Container>
  );
};
