import { useSession } from "next-auth/react";
import Link from "next/link";
import React from "react";
import { LayoutFooterLogoToolbar } from "~/components/Layout/LayoutFooterLogoToolbar/LayoutFooterLogoToolbar";
import { Body, Icon } from "~/ui-library";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
} from "~/ui-library/icons";
import NextImage from "next/image";
import { FooterLink } from "./FooterLink";

interface FooterProps<T extends React.ElementType> {
  as?: T;
  children: React.ReactNode;
}

export const Footer = <T extends React.ElementType = "footer">(
  props: FooterProps<T>
) => {
  const { as, children } = props;

  const Component = as || "footer";

  const { status } = useSession();

  return (
    <Component className="relative z-20 w-full print:hidden">
      <div className="h-0.5 bg-gradient-to-r from-brand-indigo via-brand to-brand-teal-900"></div>
      <div className="mx-auto flex max-w-[1360px] flex-col flex-wrap items-center pb-l md:min-h-[175px] md:flex-row md:justify-center md:px-m md:py-10 lg:justify-between">
        <div className="mb-l mt-10 min-w-[105px] place-content-center items-center justify-center md:my-0 md:min-w-[305px] ">
          <NextImage
            src="/assets/mcpro-logo-color.png"
            alt="logo"
            width={350}
            height={5}
            className="hidden md:block"
          />
          <NextImage
            src="/assets/mcpro-logo-condensed-color.png"
            alt="logo"
            width={105}
            height={5}
            className="md:hidden"
          />
        </div>

        {/* Contact Us, Sign in, Register */}
        <div className="px-m ">
          <LayoutFooterLogoToolbar status={status} />
        </div>
      </div>
      <div className="mx-auto flex max-w-[1360px] flex-col flex-wrap border-y-1 border-gray-300 px-m py-s md:flex-row md:items-center md:justify-center md:py-m lg:justify-between">
        {/* About, Careers, MCI, Custom Research Intelligence, Request a Demo */}
        <div className="mb-m flex flex-col items-center gap-s md:mb-0 md:flex-row md:items-center lg:flex-row lg:gap-m">
          {children}
        </div>

        {/* Social Media shares */}
        <div className="flex justify-center gap-[0.75rem]">
          <Link
            aria-label="Follow us on Facebook"
            className="px-xs py-xxs"
            href="https://www.facebook.com/MorningConsult/"
          >
            <Icon
              className="size-[20px]"
              SvgIcon={FacebookIcon}
              size="small"
              height={20}
              width={20}
            />
          </Link>
          <Link
            aria-label="Follow us on Instagram"
            className="px-xs py-xxs"
            href="https://www.instagram.com/MorningConsult"
          >
            <Icon
              className="size-[20px]"
              SvgIcon={InstagramIcon}
              size="small"
              height={20}
              width={20}
            />
          </Link>
          <Link
            aria-label="Follow us on LinkedIn"
            className="px-xs py-xxs"
            href="http://www.linkedin.com/company/1818696"
          >
            <Icon
              className="size-[20px]"
              SvgIcon={LinkedInIcon}
              size="small"
              height={20}
              width={20}
            />
          </Link>
          <Link
            aria-label="Follow us on Twitter"
            className="px-xs py-xxs"
            href="https://twitter.com/MorningConsult"
          >
            <Icon
              className="size-[20px]"
              SvgIcon={TwitterIcon}
              size="small"
              height={20}
              width={20}
            />
          </Link>
        </div>
      </div>

      <Body
        size="extraSmall"
        className="mb-s mt-m px-m text-center text-gray-900"
      >
        &copy; {new Date().getFullYear()} Morning Consult, All Rights Reserved.
        The M Logo and MORNING CONSULT are registered trademarks of Morning
        Consult Holdings, Inc.
      </Body>

      <div className="mx-auto flex max-w-[1360px] justify-center px-s pb-l">
        <div className="flex flex-col flex-wrap justify-center gap-s text-center md:flex-row md:gap-s md:text-left">
          <FooterLink
            className="text-gray-900"
            href="/terms-and-conditions-of-use"
          >
            Terms & Conditions of Use
          </FooterLink>
          <FooterLink className="text-gray-900" href="/terms-of-service">
            Terms of Service
          </FooterLink>
          <FooterLink
            className="text-gray-900"
            href={"https://morningconsult.com/privacy-policy/"}
          >
            Privacy Policy
          </FooterLink>
          <FooterLink
            className="text-gray-900"
            href={"https://morningconsult.com/security/"}
          >
            Security
          </FooterLink>
          <FooterLink
            className="text-gray-900"
            href={
              "https://morningconsult.com/do-not-sell-or-share-my-personal-information/"
            }
          >
            Do Not Sell or Share My Personal Information{" "}
          </FooterLink>
          <FooterLink
            className="text-gray-900"
            href={
              "https://morningconsult.com/privacy-policy/#section-california"
            }
          >
            California Privacy Notice
          </FooterLink>
        </div>
      </div>
    </Component>
  );
};
