import { z } from "zod";
/**
 * Configuration values are parsed, validated, and typed using Zod. See https://zod.dev/ for
 * details. Invalid configuration provided via "process.env" will throw an error which
 * should (intentionally) crash the service via an uncaught error.
 *
 * You can set defaults using `.default()`, allow undefined values using `.optional`(), and
 * coerce primitive values using `.coerce` (coerce is important for numbers and booleans).
 *
 * Next.js does some string replacement magic at build time to populate env vars. Next.js
 * does not support destructuring "process.env" and requires the properties of "process.env"
 * to be explicitly referenced like this "process.env.NODE_ENV". Any environment variables
 * prefixed with "NEXT_PUBLIC_" are exposed to the client. More information is
 * available here: https://nextjs.org/docs/basic-features/environment-variables
 *
 * A configuration entries's identifier should be descriptive and unique to make them easy to
 * find via a text search of the codebase. If you add similar identifiers please choose a
 * reasonable prefix followed by an underscore (example `DATADOG_`). If possible avoid nesting
 * one identifier within another to avoid false positives from text searches. For example
 * `BERRY_PIE` and `STRAWBERRY_PIE` are problematic where `PIE_BERRY` and `PIE_STRAWBERRY`
 * are not.
 *
 * Please add a JSDoc style description to any configuration entries you add. This serves as
 * documentation within this file and provides helpful tooltips in VSCode.
 *
 *  ** IMPORTANT ** This config file should only contain values which can be exposed to the
 * client (browser). Including any server configuration from process.env in this file may
 * cause the client to crash upon validation because Next.js will not expose env vars to the
 * browser unless they begin with "NEXT_PUBLIC_". We also don't want to expose the names of
 * our server env vars to the client if we can avoid it. For that reason, this file should be
 * imported directly where needed and should not be included in any barrel files, or imported
 * alongside server-config.ts
 *
 */

const environmentSchema = z
  .enum(["development", "staging", "production"])
  .default("development");

export type Environment = z.infer<typeof environmentSchema>;

export const commonConfig = Object.freeze(
  z
    .object({
      /**
       * The application ID used by DataDog for Real User Monitoring (RUM).
       */
      NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID: z.string().optional(),

      /**
       * The client token used to connect to DataDog for Real User Monitoring (RUM).
       */
      NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN: z.string().optional(),

      /**
       * The client token used to connect to DataDog for Real User Monitoring (RUM).
       */
      NEXT_PUBLIC_DATADOG_RUM_SAMPLE_RATE: z.coerce.number().int().default(0),

      /**
       * The channel ID used to subscribe / unsubscribe from the marketing channel (all messages)
       */
      NEXT_PUBLIC_EMAIL_MARKETING_CHANNEL_ID: z.coerce.number().int(),

      /**
       * The message ID used to subscribe / unsubscribe from marketing messages
       */
      NEXT_PUBLIC_EMAIL_MARKETING_MESSAGE_ID: z.coerce.number().int(),

      /**
       * The current application URL
       */
      NEXT_PUBLIC_ENDIL_URL: z.string().url(),

      /**
       * The application deployment environment.
       */
      NEXT_PUBLIC_ENDIL_ENV: environmentSchema.default("development"),

      /**
       * Endil application version
       */
      NEXT_PUBLIC_ENDIL_VERSION: z.string().default("0"),

      /**
       * Identity provider.
       */
      NEXT_PUBLIC_ENDIL_IDENTITY_PROVIDER: z.enum(["okta"]).default("okta"),

      /**
       * Google identity provider.
       */
      NEXT_PUBLIC_GOOGLE_IDP: z.string(),

      /**
       * LinkedIn identity provider.
       */
      NEXT_PUBLIC_LINKEDIN_IDP: z.string(),

      /**
       * Microsoft identity provider.
       */
      NEXT_PUBLIC_MICROSOFT_IDP: z.string(),

      /**
       * MC Platform URL for Field home
       */
      NEXT_PUBLIC_MC_FIELD_URL: z.string().url(),

      /**
       * MC Platform URL for MCI home
       */
      NEXT_PUBLIC_MCI_URL: z.string().url(),

      /**
       * MC Platform URL for MCI Login page
       */
      NEXT_PUBLIC_MCI_LOGIN_URL: z.string().url(),

      /**
       * The piano.io application ID. This value is used for all API
       * requests and initializing the SDK.
       */
      NEXT_PUBLIC_PIANO_APPLICATION_ID: z.string(),

      /**
       * The piano.io application ID. This value is used for all API
       * requests and initializing the SDK.
       */
      NEXT_PUBLIC_PIANO_PRO_RESOURCE: z.string(),

      /**
       * Whether to toggle on verbose Piano SDK logging
       */
      NEXT_PUBLIC_PIANO_DEBUG: z
        .string()
        .regex(/^true|false$/i)
        .transform((value) => value.toLowerCase() === "true")
        .optional()
        .default("false"),

      /**
       * The key used by segment to identify a source of analytics data
       */
      NEXT_PUBLIC_SEGMENT_WRITE_KEY: z.string().optional(),

      /**
       * Whether to proxy via custom domain.
       *
       * Defaults to on.
       */
      NEXT_PUBLIC_SEGMENT_SHOULD_PROXY: z
        .string()
        .regex(/^true|false$/i)
        .transform((value) => value.toLowerCase() === "true")
        .optional()
        .default("true"),

      /**
       * The piano.io template ID.
       */
      NEXT_PUBLIC_PIANO_REGISTRATION_TEMPLATE_ID: z.string(),

      /** Public sitekey for turnstile. */
      NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITEKEY: z.string(),
    })
    .parse({
      NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID:
        process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID,
      NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN:
        process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
      NEXT_PUBLIC_DATADOG_RUM_SAMPLE_RATE:
        process.env.NEXT_PUBLIC_DATADOG_RUM_SAMPLE_RATE,
      NEXT_PUBLIC_EMAIL_MARKETING_CHANNEL_ID:
        process.env.NEXT_PUBLIC_EMAIL_MARKETING_CHANNEL_ID,
      NEXT_PUBLIC_EMAIL_MARKETING_MESSAGE_ID:
        process.env.NEXT_PUBLIC_EMAIL_MARKETING_MESSAGE_ID,
      NEXT_PUBLIC_ENDIL_ENV: process.env!.NEXT_PUBLIC_ENDIL_ENV,
      NEXT_PUBLIC_ENDIL_VERSION: process.env!.NEXT_PUBLIC_ENDIL_VERSION,
      NEXT_PUBLIC_ENDIL_IDENTITY_PROVIDER:
        process.env!.NEXT_PUBLIC_ENDIL_IDENTITY_PROVIDER,
      NEXT_PUBLIC_GOOGLE_IDP: process.env!.NEXT_PUBLIC_GOOGLE_IDP,
      NEXT_PUBLIC_LINKEDIN_IDP: process.env!.NEXT_PUBLIC_LINKEDIN_IDP,
      NEXT_PUBLIC_MICROSOFT_IDP: process.env!.NEXT_PUBLIC_MICROSOFT_IDP,
      NEXT_PUBLIC_PIANO_APPLICATION_ID:
        process.env!.NEXT_PUBLIC_PIANO_APPLICATION_ID,
      NEXT_PUBLIC_PIANO_PRO_RESOURCE:
        process.env!.NEXT_PUBLIC_PIANO_PRO_RESOURCE,
      NEXT_PUBLIC_PIANO_DEBUG: process.env!.NEXT_PUBLIC_PIANO_DEBUG,
      NEXT_PUBLIC_SEGMENT_WRITE_KEY: process.env!.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
      NEXT_PUBLIC_SEGMENT_SHOULD_PROXY:
        process.env!.NEXT_PUBLIC_SEGMENT_SHOULD_PROXY,
      NEXT_PUBLIC_ENDIL_URL: process.env!.NEXT_PUBLIC_ENDIL_URL,
      NEXT_PUBLIC_MC_FIELD_URL: process.env!.NEXT_PUBLIC_MC_FIELD_URL,
      NEXT_PUBLIC_MCI_URL: process.env!.NEXT_PUBLIC_MCI_URL,
      NEXT_PUBLIC_MCI_LOGIN_URL: process.env!.NEXT_PUBLIC_MCI_LOGIN_URL,
      NEXT_PUBLIC_PIANO_REGISTRATION_TEMPLATE_ID:
        process.env!.NEXT_PUBLIC_PIANO_REGISTRATION_TEMPLATE_ID,
      NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITEKEY:
        process.env!.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITEKEY,
    })
);
