import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

type HeaderBannerProps = JSX.IntrinsicElements["div"];

export const HeaderBanner: React.FC<HeaderBannerProps> = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { events } = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      ref.current?.classList.add("hidden");
    };
    events.on("routeChangeComplete", handleRouteChange);
    return () => {
      events.on("routeChangeComplete", handleRouteChange);
    };
  }, [events]);

  return (
    <div
      className="sticky top-[64px] z-30 w-full"
      id="header-banner-container"
      data-testid="header-banner-container"
      ref={ref}
    >
      <div id="piano-header-banner"></div>
    </div>
  );
};
