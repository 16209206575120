import { commonConfig } from "~/config/common-config";

export enum SubscriptionName {
  FREE_TRIAL_SUBSCRIPTION = "free_trial_subscription",
  ONE_SEAT_SUBSCRIPTION = "one_seat_subscription",
  FIVE_SEATS_SUBSCRIPTION = "five_seat_subscription",
}

export type PianoOffer = {
  offerId: string;
  termId: string;
};

type SelectedHeaderProps = {
  seatCount: number;
  pricePerMonth: number;
  pricePerYear: number;
  strikethroughPrice: number | null;
};

type SubCheckoutSelectedSubscriptionProps = {
  isFreeTrial: boolean;
  valueTextItems: string[];
  footerText: string;
} & SelectedHeaderProps;

export type SubscriptionData = {
  pianoOffer: PianoOffer;
  /** static props used by the account setting overview screen */
  accountOverviewProps: {
    label: string;
  };
  subCheckoutSelectedSubscriptionProps: SubCheckoutSelectedSubscriptionProps;
};

const isPianoSandbox = commonConfig.NEXT_PUBLIC_ENDIL_ENV !== "production";

export const subscriptionMap = new Map<SubscriptionName, SubscriptionData>([
  [
    SubscriptionName.FREE_TRIAL_SUBSCRIPTION,
    {
      pianoOffer: {
        offerId: isPianoSandbox ? "OFVNAK6DQY7K" : "OF7KFA6Y3ATN",
        termId: isPianoSandbox ? "TMOR5TWR4GZL" : "TM6STWAM9DYH",
      },
      accountOverviewProps: {
        label: "Free Trial - Individual Subscription (1 Seat)",
      },
      subCheckoutSelectedSubscriptionProps: {
        isFreeTrial: true,
        seatCount: 1,
        pricePerMonth: 99,
        pricePerYear: 1100,
        strikethroughPrice: 1200,
        valueTextItems: [
          "<strong>Unlimited</strong> access to analysis",
          "<strong>Unlimited</strong> report downloads",
          "<strong>Unlimited</strong> chart pack downloads",
        ],
        footerText:
          "Free trial promotion is limited to individual accounts. Free trial accounts are automatically charged after 30 days and renew each year until you cancel. You can cancel at any time during your trial in your Account Settings.",
      },
    },
  ],
  [
    SubscriptionName.ONE_SEAT_SUBSCRIPTION,
    {
      pianoOffer: {
        offerId: isPianoSandbox ? "OFVNAK6DQY7K" : "OF7KFA6Y3ATN",
        termId: isPianoSandbox ? "TMEHFNC49L46" : "TMZCWQMP4VE0",
      },
      accountOverviewProps: {
        label: "Individual Subscription (1 Seat)",
      },
      subCheckoutSelectedSubscriptionProps: {
        isFreeTrial: false,
        seatCount: 1,
        pricePerMonth: 99,
        pricePerYear: 1200,
        strikethroughPrice: null,
        valueTextItems: [
          "<strong>Unlimited</strong> access to analysis",
          "<strong>Unlimited</strong> report downloads",
          "<strong>Unlimited</strong> chart pack downloads",
        ],
        footerText:
          "Subscriptions automatically renew each year until you cancel. Taxes may apply. You can cancel at any time during your trial in your account settings. Cancellation goes into effect at the end of your current billing period. ",
      },
    },
  ],
  [
    SubscriptionName.FIVE_SEATS_SUBSCRIPTION,
    {
      pianoOffer: {
        offerId: isPianoSandbox ? "OFVNAK6DQY7K" : "OF7KFA6Y3ATN",
        termId: isPianoSandbox ? "TM4PCQFTW8YY" : "TMA3LJW6Z6SI",
      },
      accountOverviewProps: {
        label: "Team Subscription (5 Seats)",
      },
      subCheckoutSelectedSubscriptionProps: {
        isFreeTrial: false,
        seatCount: 5,
        pricePerMonth: 83,
        pricePerYear: 5000,
        strikethroughPrice: null,
        valueTextItems: [
          "<strong>Unlimited</strong> access to analysis",
          "<strong>Unlimited</strong> report downloads",
          "<strong>Unlimited</strong> chart pack downloads",
        ],
        footerText:
          "Subscriptions automatically renew each year until you cancel. Taxes may apply. You can cancel at any time during your trial in your account settings. Cancellation goes into effect at the end of your current billing period.",
      },
    },
  ],
]);

export const termIdToSubscriptionNameMap = new Map<string, SubscriptionName>(
  Array.from(subscriptionMap.entries()).map(
    ([subscriptionName, subscriptionData]) => [
      subscriptionData.pianoOffer.termId,
      subscriptionName,
    ]
  )
);
