import { PropsWithChildren, createContext, useContext } from "react";
import { SubscriptionName } from "~/lib/shared/subscriptions";
import { TinyPass } from "./piano-types";

export enum PianoSDKStatus {
  /**
   * SDK has not yet been loaded via script tag.
   */
  NotReady = 0,

  /**
   * The SDK is loaded and initialized.
   */
  Ready = 1,
}

export type PianoContextProps =
  | {
      pianoStatus: PianoSDKStatus.NotReady;
      piano: null;
      hasExperienceExecuted: boolean;
    }
  | {
      pianoStatus: PianoSDKStatus.Ready;
      piano: TinyPass;
      paywallShown: boolean;
      promotionalBannerSlug?: string;
      activeSubscriptionName?: SubscriptionName | null;
      hasExperienceExecuted: boolean;
    };

const PianoContext = createContext<PianoContextProps>({
  pianoStatus: PianoSDKStatus.NotReady,
  piano: null,
  hasExperienceExecuted: false,
});

export const PianoContextProvider = ({
  children,
  ...props
}: PropsWithChildren<PianoContextProps>): JSX.Element => (
  <PianoContext.Provider value={props}>{children}</PianoContext.Provider>
);

export const usePiano = (): PianoContextProps => useContext(PianoContext);
