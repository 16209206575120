export enum PostTypeEnum {
  Acf = "acf",
  AcfField = "acf-field",
  AcfFieldGroup = "acf-field-group",
  Analysis = "analysis",
  AnalystReport = "analyst_report",
  Article = "article",
  Author = "author",
  Attachment = "attachment",
  InstantIntel = "instant_intel",
  LandingPage = "landing_page",
  OembedCache = "oembed_cache",
  Post = "post",
  QuestionAnswer = "q_and_a",
  Revision = "revision",
  ReportLibrary = "reports",
  // not an actual post type, simply used for routing
  SubscriptionCheckout = "subscription_checkout",
  TablepressTable = "tablepress_table",
  Tracker = "tracker",
  TrendSetter = "trend_setter",
  Vertical = "vertical",
}

export type PostType = keyof typeof PostTypeEnum;
