/* We should always create elements as React components and set attributes via
props, rather than using something like dangerouslySetInnerHTML, so that React
adds  attribute values as text. Taking this approach means we shouldn't need to 
encode attribute values because they should not be able to break out of their
tag for XSS. */
import { CSSProperties, ReactNode } from "react";
import { Link, Headline, LinkProps } from "~/ui-library";
import { Attribute } from "parse5/dist/common/token";
import { Element } from "parse5/dist/tree-adapters/default";
import {
  createSetValueConverter,
  createStyleConverter,
  createTextOrBooleanConverter,
  createURLConverter,
} from "./attribute-to-props-factories";

export type TagToClassNameMap = Map<keyof JSX.IntrinsicElements, string>;

export type TagConverterOptions = {
  allowStyleAttribute?: boolean;
};

export interface TagConverter {
  (
    node: Element,
    children: ReactNode,
    tagToClassNameMap: TagToClassNameMap,
    key: number | string,
    options: TagConverterOptions
  ): ReactNode;
}

export const downloadConverter = createTextOrBooleanConverter("download");

export const hrefConverter = createURLConverter("href");

export const targetConverter = createSetValueConverter(
  "target",
  new Set(["_blank", "_parent", "_self", "_top"])
);

export const styleConverter = createStyleConverter();

export const anchorAttributesToLinkProps = (node: Element): LinkProps =>
  node.attrs.reduce(
    (linkPropsResult: LinkProps, attribute: Attribute) =>
      Object.assign(
        linkPropsResult,
        downloadConverter(attribute),
        hrefConverter(attribute),
        targetConverter(attribute)
      ),
    { href: "" }
  );

export const styleAttributeToProps = (
  node: Element
): { style?: CSSProperties } => {
  const styleAttribute = node.attrs.find(
    (attribute) => attribute.name === "style"
  );

  if (!styleAttribute) {
    return {};
  }

  return styleConverter(styleAttribute);
};

export const extractChildren: TagConverter = (node, children) => children;

export type TagToReactConverters = Map<
  keyof JSX.IntrinsicElements,
  TagConverter
>;

// This Map also serves as an allow list for tagNames
export const defaultTagToReactConverters: TagToReactConverters = new Map<
  keyof JSX.IntrinsicElements,
  TagConverter
>([
  [
    "a",
    (node, children, tagToClassNameMap, key) => (
      <Link
        {...anchorAttributesToLinkProps(node)}
        className={tagToClassNameMap.get("a")}
        key={key}
      >
        {children}
      </Link>
    ),
  ],
  [
    "b",
    (node, children, tagToClassNameMap, key) => (
      <strong className={tagToClassNameMap.get("b")} key={key}>
        {children}
      </strong>
    ),
  ],
  [
    "br",
    (node, children, tagToClassNameMap, key) => (
      <br className={tagToClassNameMap.get("br")} key={key} />
    ),
  ],
  [
    "div",
    (node, children, tagToClassNameMap, key) => (
      <div className={tagToClassNameMap.get("div")} key={key}>
        {children}
      </div>
    ),
  ],
  [
    "em",
    (node, children, tagToClassNameMap, key) => (
      <em className={tagToClassNameMap.get("em")} key={key}>
        {children}
      </em>
    ),
  ],
  [
    "h1",
    (node, children, tagToClassNameMap, key) => (
      <Headline
        level={1}
        as="h1"
        className={tagToClassNameMap.get("h1")}
        key={key}
        {...styleAttributeToProps(node)}
      >
        {children}
      </Headline>
    ),
  ],
  [
    "h2",
    (node, children, tagToClassNameMap, key) => (
      <Headline
        level={2}
        as="h2"
        className={tagToClassNameMap.get("h2")}
        key={key}
      >
        {children}
      </Headline>
    ),
  ],
  [
    "h3",
    (node, children, tagToClassNameMap, key) => (
      <Headline
        level={3}
        as="h3"
        className={tagToClassNameMap.get("h3")}
        key={key}
        {...styleAttributeToProps(node)}
      >
        {children}
      </Headline>
    ),
  ],
  [
    "h4",
    (node, children, tagToClassNameMap, key) => (
      <Headline
        level={4}
        as="h4"
        className={tagToClassNameMap.get("h4")}
        key={key}
        {...styleAttributeToProps(node)}
      >
        {children}
      </Headline>
    ),
  ],
  [
    "h5",
    (node, children, tagToClassNameMap, key) => (
      <Headline
        level={5}
        as="div"
        blockCase
        className={tagToClassNameMap.get("h5")}
        key={key}
        {...styleAttributeToProps(node)}
      >
        {children}
      </Headline>
    ),
  ],
  [
    "h6",
    (node, children, tagToClassNameMap, key) => (
      <Headline
        level={6}
        as="h6"
        blockCase
        className={tagToClassNameMap.get("h6")}
        key={key}
      >
        {children}
      </Headline>
    ),
  ],
  [
    "hr",
    (node, children, tagToClassNameMap, key) => (
      <hr className={tagToClassNameMap.get("hr")} key={key} />
    ),
  ],
  [
    "i",
    (node, children, tagToClassNameMap, key) => (
      <i className={tagToClassNameMap.get("i")} key={key}>
        {children}
      </i>
    ),
  ],
  [
    "li",
    (node, children, tagToClassNameMap, key) => (
      <li className={tagToClassNameMap.get("li")} key={key}>
        {children}
      </li>
    ),
  ],
  [
    "ol",
    (node, children, tagToClassNameMap, key) => (
      <ol className={tagToClassNameMap.get("ol")} key={key}>
        {children}
      </ol>
    ),
  ],
  [
    "p",
    (node, children, tagToClassNameMap, key) => (
      <p className={tagToClassNameMap.get("p")} key={key}>
        {children}
      </p>
    ),
  ],
  [
    "u",
    (node, children, tagToClassNameMap, key) => (
      <span className={(tagToClassNameMap.get("u"), "underline")} key={key}>
        {children}
      </span>
    ),
  ],
  [
    "span",
    (...args) => {
      const [node, children, tagToClassNameMap, key, { allowStyleAttribute }] =
        args;

      if (!allowStyleAttribute) {
        return extractChildren(...args);
      }

      return (
        <span
          className={tagToClassNameMap.get("span")}
          key={key}
          {...styleAttributeToProps(node)}
        >
          {children}
        </span>
      );
    },
  ],
  [
    "strong",
    (node, children, tagToClassNameMap, key) => (
      <strong className={tagToClassNameMap.get("strong")} key={key}>
        {children}
      </strong>
    ),
  ],
  [
    "ul",
    (node, children, tagToClassNameMap, key) => (
      <ul className={tagToClassNameMap.get("ul")} key={key}>
        {children}
      </ul>
    ),
  ],
]);

// This Map also serves as an allow list for tagNames
export const extractTagToReactConverters: TagToReactConverters = new Map<
  keyof JSX.IntrinsicElements,
  TagConverter
>([
  ["a", extractChildren],
  ["b", extractChildren],
  ["br", extractChildren],
  ["div", extractChildren],
  ["em", extractChildren],
  ["h1", extractChildren],
  ["h2", extractChildren],
  ["h3", extractChildren],
  ["h4", extractChildren],
  ["h5", extractChildren],
  ["h6", extractChildren],
  ["hr", extractChildren],
  ["i", extractChildren],
  ["li", extractChildren],
  ["ol", extractChildren],
  ["p", extractChildren],
  ["span", extractChildren],
  ["strong", extractChildren],
  ["ul", extractChildren],
]);
