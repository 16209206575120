import { cva } from "cva";

export const linkStyles = cva(
  [
    "items-center",
    "outline-2 outline-offset-2",
    "focus-visible:outline-brand",
    "cursor-pointer",
  ],
  {
    variants: {
      appearance: {
        default: ["text-primary hover:underline"],
        bold: ["font-semibold"],
        plain: ["hover:underline"],
      },
    },
    defaultVariants: {
      appearance: "default",
    },
  }
);
