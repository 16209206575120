import React from "react";
import { useAnalytics } from "~/components/Analytics";
import { Footer, FooterLink } from "~/components/Footer";

export interface LayoutFooterProps<T extends React.ElementType> {
  as?: T;
}

export function LayoutFooter<T extends React.ElementType = "header">({
  as,
}: LayoutFooterProps<T>) {
  const { analyticsEnhancedTrack } = useAnalytics();
  const mciOnClick = () => analyticsEnhancedTrack("MCI Click");

  return (
    <Footer as={as}>
      <FooterLink href={"https://morningconsult.com/company/about/"}>
        About
      </FooterLink>
      <FooterLink href={"https://morningconsult.com/company/careers/"}>
        Careers
      </FooterLink>
      <FooterLink
        href={"https://morningconsult.com/intelligence-platform/"}
        onClick={mciOnClick}
      >
        Morning Consult Intelligence
      </FooterLink>
      <FooterLink href={"https://morningconsult.com/custom-research/"}>
        Custom Research Intelligence
      </FooterLink>
      <FooterLink href={"https://info.morningconsult.com/request-a-demo"}>
        Request a Demo
      </FooterLink>
    </Footer>
  );
}
